import './App.css';
import {AppBody} from "./components/app_body";
import {AppHeader} from "./components/addHeader";

function App() {
  return (
      <div className="App">
          <AppHeader />
          <AppBody />
      </div>
  );
}

export default App;
