import '../App.css';
import logo from "../img/FullLogo_Transparent_NoBuffer.png";
import {useEffect} from "react";
import waitForElementTransition from 'wait-for-element-transition';

export function AppHeader() {
    // Handler when page is scrolled
    const handleScroll = () => {
        const imgElement = document.querySelector('img')
        const hdrElement = document.querySelector('header')
        console.log(imgElement)
        console.log(hdrElement)
        if (40 < Math.round(window.scrollY)) {
            imgElement.setAttribute("height", "150")
            hdrElement.setAttribute("style", "align-items: flex-start")
            window.removeEventListener('scroll', handleScroll);
        }
    }

    useEffect(() => {
        // Adding the scroll listener { passive: true }
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            // Removing listener
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <header
            className={`App-header`}>
            <img
                src={logo}
                className={`header-image`}
                alt="logo"
                height="500"/>
        </header>
    )
}